import React from "react";
import Data from "../../Data/Data.json";
import { HeroBackground } from "../Images/Images";

const Footer = () => {
  return (
    <div
      className="container-fluid footer-dark text-light footer pt-5 wow fadeIn"
      data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary txt-dark text-start text-primary fw-normal mb-4">
              Company
            </h4>
           
            {Data.FooterLinks.map((key,index) => {
              return (
                <a className="btn btn-link" key={index} href={key.id}>
                  {key.Name}
                </a>
              );
            })}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 txt-dark">
              Contact
            </h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>
              {Data.Address}
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>
              {Data.Phone}
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>{Data.Email}
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Facebook}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Instagram}>
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Linkedin}>
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 txt-dark">
              Opening
            </h4>
            {Data.Timings.map((key, index) => {
              return (
                <span key={index}>
                  <p className=" fw-normal">{key.Days}<br/><span >{key.Time}</span></p>
                  
                </span>
              );
            })}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 txt-dark">
              {Data.Name}
            </h4>
            
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: "400px" }}>
            <img src={HeroBackground} width={"150px"} alt="logo"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a className="border-bottom" href="http://www.skiptheq.co.uk">
                {Data.Name}
              </a>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Managed By <a className="border-bottom" href="https://www.dazzlingwebplanet.com">
              Dazzling Web Planet
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
