import React from "react";
import Data from "../../Data/Data.json";

const Comparsion = () => {
  return (
    <div className="my-2 mb-4">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12 text-center" id="become_partner">
            <h1 className="mb-4 d-flex justify-content-center d-blk">
                How much can you save by switching to&nbsp;<span className={`text-primary me-2`}>{Data.Name}</span>
            </h1>
        </div>
        <div className="col-12 col-lg-6 offset-lg-3 px-0">
        
          <div className="col-lg-12 text-center" id="become_partner">
            <p className="lead mb-5 mb-lg-0">
              <br />
              The total amount you are getting charged by Others:
            </p>
            <h3 className="mb-3">
              <span>Commision</span>
            </h3>
          </div>
        </div>
        <div className="col-12 col-lg-6 offset-lg-3 px-0">
          <div className="table-responsive text-center">
            <table className="table table-bordered table-borderedpart mb-0">
              <thead>
                <tr>
                  <th scope="col">&nbsp;</th>
                  <th scope="col" className="table-active">
                    <span>Others</span>
                  </th>
                  <th scope="col" className="bgi-primary">
                    <span>{Data.Name}</span>
                  </th>
                  <th scope="col" style={{color:"#d82927",backgroundColor: "#fff"}}>
                    <span>Savings</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  Data.Comparison.map((Order,index)=>{
                    return(
                      <tr key={index}>
                  <th scope="row">
                    <span>
                      Per {Order.id}
                      <br />
                      {Order.Count}
                    </span>
                  </th>
                  <td className="table-active">
                    {Order.Others}
                    <span className="text-small">
                      &nbsp;{" "}
                      <span>
                        <br />
                        inc Vat
                      </span>
                    </span>
                  </td>
                  <td className="bgi-primary">
                    <span className="text-small">
                      {Order.SkipTheQ}
                      <span>
                        {" "}
                        <br />
                        inc Vat
                      </span>
                    </span>
                  </td>
                  <td style={{color:"#d82927",backgroundColor: "#fff"}}>
                    <span className="text-small">
                      {Order.Difference}<span><br/> inc Vat</span>
                    </span>
                  </td>
                </tr>
                    )
                  })
                }
                {/* <tr>
                  <th scope="row">
                    <span>
                      Per Week
                      <br />
                      400 order
                    </span>
                  </th>
                  <td className="table-active">
                    £3600.00
                    <span className="text-small">
                      &nbsp;{" "}
                      <span>
                        <br />
                        inc Vat
                      </span>
                    </span>
                  </td>
                  <td className="bgi-primary">
                    £
                    <span className="text-small">
                      400
                      <span>
                        {" "}
                        <br />
                        inc Vat
                      </span>
                    </span>
                  </td>
                  <td style={{color:"#d82927",backgroundColor: "#fff"}}>
                    £
                    <span className="text-small">
                      1804<span> inc Vat</span>
                    </span>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <span className="text-small">
            <span>*Please be advised that all figures are approximate.</span>
          </span>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Comparsion;