import React from "react";
import "./ClientSlider.css";
import { Clients } from "../Images/Images";
import Data from "../../Data/Data.json";

const ClientSlider = () => {
  const MList = () => {
    return (
      <ul className="logo-strip__list">
        {Data.Clients.map((Client, index) => {
          return (
            <li className="logo-strip__item" key={index}>
              <div className="logo-strip__link">
                <picture className="logo-strip__picture">
                  <source
                    srcset={Clients[Client.Name]?.Webp}
                    type="image/webp"
                  />
                  <img
                    src={Clients[Client.Name]?.Png}
                    alt={Client.Alt}
                    className="logo-strip__image"
                  />
                </picture>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <div className="container z-9 py-2">
        <div className="col-lg-12">
          <h1 className="mb-4 d-flex justify-content-center d-blk d-cntnts">
          Trusted by&nbsp;<span className={`text-primary me-2`}>Discerning Clients</span>,&nbsp; Without Exception
          </h1>
        </div>
      </div>
      <div className="logo-strip logo-strip--no-margin-bottom marquee">
        <div className="marquee__container" role="marquee">
          <div className="marquee__wrapper">
            <div
              className="marquee__first animating"
              style={{ animationDuration: "60s" }}>
              {MList()}
              {MList()}
            </div>
            <div
              className="marquee__second animating"
              style={{ animationDuration: "60s" }}>
              {MList()}
              {MList()}
            </div>
            <div
              className="marquee__second animating"
              style={{ animationDuration: "60s" }}>
              {MList()}
              {MList()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSlider;
