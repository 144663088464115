import React from "react";
import "./Service.css";
import Data from "../../Data/Data.json";
import { ServiceBanner } from "../Images/Images";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const Service = () => {
  return (
    <>
    <div style={{ backgroundColor: "#f7eada" }}>
      <div className="container py-4" >
        <div className="row py-2">
        <div className="col-lg-4 col-sm-12 service-img">
        <img className="img-fluid rounded w-100 wow zoomIn" alt="About" data-wow-delay="0.7s" src={ServiceBanner}/>
        </div>
        <div className="col-lg-2"></div>
        <div className="col-lg-6 col-sm-12">
          {Data.Benefits.map((Benefit, index) => {
            return (
              <div key={index} className="d-flex">
                <ArrowRightIcon className="service-index"/>
                <h3 className="service-index" id={"service-index"+index}>0{index + 1}</h3>
                <div className="service-desc row my-2 t-center">
                  <h5 className="col-md-5 col-sm-12">{Benefit.Name}</h5>
                  <span className="col-md-7 col-sm-12">{Benefit.Description}</span>
                </div>
              </div>
            );
          })}
        </div>
        </div>
    </div>
      </div>
    </>
  );
};

export default Service;
