import React from "react";
import Data from "../../Data/Data.json";
import { Icons } from "../Images/Images";
import { Industries } from "../Images/Images";
import "./Common.css";
const Services = (props) => {
  return (
    <div className="py-5">
      <div className="container">
        <div className='col-lg-12'>
          <h1 className="mb-4 d-flex justify-content-center d-blk z-9">
            <span className={`text-primary me-2`}>Industries</span>&nbsp;We Serve
          </h1>
        </div>
        <div className="g-4 GridP">
          <section class=" text-center">
            <div class="innerContainer">
              <p class="paddingBottom45 industries">
                Business isn't one size fits all. Every industry requires a custom solution.<br/>
              </p>
              <div class="ct-tabs-outer js-tabs">
                <div class="row text-center">
                  {Data.Industries.map((industry,index)=>{
                    return(  <div class="col-md-2 col-sm-4 col-xs-4">
                    <div class="item v">
                        <div class="inner">
                          &nbsp;
                          <img src={Industries[index]} className="fa" alt="Industries We Serve"/>
                        </div>
                        <h2 className="mt-2">
                          {industry.Name}
                        </h2>
                    </div>
                  </div>)
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  );
};

export default Services;

