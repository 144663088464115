import Testimonial1   from "../../img/testimonial-1.jpg";
import Testimonial2   from "../../img/testimonial-2.jpg";
import Testimonial3   from "../../img/testimonial-3.jpg";
import Testimonial4   from "../../img/testimonial-4.jpg";
import HeroPng        from "../../img/bg-hero.png";
import About1         from "../../img/lionabout-1.jpeg";
import About2         from "../../img/lionabout-2.jpeg";
import About3         from "../../img/lionabout-3.jpeg";
import About4         from "../../img/lionabout-4.jpeg";
import Team1          from "../../img/team-1.jpg";
import Team2          from "../../img/team-2.jpg";
import Team3          from "../../img/team-3.jpg";
import Team4          from "../../img/team-4.jpg";
import Menu           from "../../img/lionmenu.png";

//Icons
import Customer       from "../../img/customer.png";
import Pickup         from "../../img/pickup.png";
import Order          from "../../img/order.png";
import Mobile         from "../../img/mobhand.png";
import Marketing      from "../../img/marketing.png";
import Website        from "../../img/website.png";
import Delivery       from "../../img/delivery.png";
import Retail         from "../../img/retail.png";
import Art            from "../../img/art.png";
import Cafe           from "../../img/cafe.png";
import Food           from "../../img/food.png";
import Hospitality    from "../../img/hospitality.png";
import Media          from "../../img/media.png";
import Takeaway       from "../../img/takeaway.png";

//Features
import WebApp         from "../../img/WebApp.png";
import App            from "../../img/App.png";
import Market         from "../../img/Market.png";
import AllFeatures    from "../../img/Feature.gif";

//Contact
import Contact        from "../../img/Contact.png";

//Services
import Services       from "../../img/Services.png";

//Clients
import GrilletWebp    from "../../img/grillet.webp";
import KaliPng        from "../../img/kali.png";
import DunningsWebp   from "../../img/dunnings.webp";






//Exports
export const MenuImg        = Menu;
export const Abouts         = [About2, About3, About4, About2];
export const Testimonials   = [Testimonial1, Testimonial2, Testimonial3, Testimonial4];
export const Teams          = [Team1, Team2, Team3, Team4, Team4];
export const HeroBackground = HeroPng;
export const Icons          = [Order, Takeaway, Pickup, Mobile];
export const Features       = [WebApp, App, Market];
export const FeaturesWrap   = AllFeatures;
export const ContactUs      = Contact;
export const Industries     = [Food,Takeaway, Cafe, Retail, Art, Hospitality];
export const ServiceBanner  = Services;
export const Clients        = {
   "KaliMirchi" : {
        "Webp" : "",
        "Png"  : KaliPng
    },
    "Grillet" :
    {
        "Webp" : GrilletWebp,
        "Png"  : ""
    },
    "Dunnings" :
    {
        "Webp" : DunningsWebp,
        "Png"  : ""  
    }
};

