import About from "../About/About";
import Contact from "../Contact/Contact";
import Services from "../Common/Services";
import Work from "../Common/Work";
import Service from "../Services/Service";
import ClientSlider from "../Common/ClientSlider";
import Comparsion from "../Common/Comparison";

const Home = () => {
  return (
    <>
      <ClientSlider/>
      <About />
      <Work/>
      <Comparsion/>
      <Service/>
      <Contact />
      <Services/>
    </>
  );
};

export default Home;
