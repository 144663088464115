import React from "react";
import Data from "../../Data/Data.json";
import { HeroBackground } from "../Images/Images";


const Navbar = (props) => {
return(        
<nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 nav-bg sticky-top shadow-sm" >
    <header className="container"><a href="/" className="navbar-brand p-0">
        <img src={HeroBackground} width={"200px"} alt="Background Img"/>
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="fa fa-bars"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0 pe-4">
            {
                Data.Nav.map((key)=>{
                    if(Data.Active[key.id]) return(<a href={key.Route} key={key.id} className="nav-item nav-link">{key.id}</a>)
                })
            }
            
            {/* <a href="about" className="nav-item nav-link">About</a> */}
            {/* <a href="service" className="nav-item nav-link">Service</a> */}
            {/* <a href="menu" className="nav-item nav-link">Menu</a> */}
            {/* <a href="team" className="nav-item nav-link">Our Team</a> */}
            {/* <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                <div className="dropdown-menu m-0">
                    <a href="booking" className="dropdown-item">Booking</a>
                    <a href="team" className="dropdown-item">Our Team</a>
                    <a href="testimonial" className="dropdown-item">Testimonial</a>
                </div>
            </div> */}
            {/* <a href="contact" className="nav-item nav-link">Contact</a> */}
        </div>
        <a href="/contact" className="btn btn-primary py-2 px-4 ft-black">Book A Demo</a>
    </div></header>
</nav>);
};

export default Navbar;