import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import AddressForm from "../Checkout/AddressForm";

const Contact = (props) => {
  const path = useLocation();
  
  return (
    <>
      <div className="py-5 px-0 animate fadeInUp container" data-wow-delay="0.1s">
        <div className="row g-0">
      
          <div className="col-md-6">
          {/* {!path.pathname.includes("contact") ?<h5 className="section-title ff-secondary text-start text-primary fw-normal">
                Contact Us
              </h5>: ""} */}
            <div className="video">
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center z-9">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              
              <h1 className="text-black mb-4">Book A Demo</h1>
              <div className="row g-3 text-white">
                <AddressForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;

