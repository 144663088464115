import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HeroBackground } from "../Images/Images";
import Data from "../../Data/Data.json";
import TrustPilot from "../Common/TrustPilot";
import BannerV from "../../img/banner.mp4";

const Banner = () => { 
    const path = useLocation();
    const [Breadcrumb,setBreadcrumb] = useState([{id:"",value:""}]);
    
    useEffect(()=>{
        Data.Nav.forEach((key)=>{
            if(path.pathname.includes(key.id.toLowerCase())){
                setBreadcrumb({id:key.id,value:key.Value})
            }
        })
    },[])

    return(
    
    <div className="pt-3 bg-dark nav-bg pti-0">
        {path.pathname == "/"?
        <div data-poster-url="" data-video-urls={BannerV} data-autoplay="true" data-loop="true" data-wf-ignore="true" data-beta-bgvideo-upgrade="false" className="hero-section-features w-background-video w-background-video-atom">
        <video id="c6a7633f-17f4-98ef-ace8-e7f92b76c422-video" autoPlay loop className="hero-header" muted playsInline="" data-wf-ignore="true" data-object-fit="cover" style={{opacity: "0.7"}}>
          <source src={BannerV} data-wf-ignore="true"/>
          {/* <source src="https://uploads-ssl.webflow.com/5e5a87a53c3e0f76a5eba371/6278ea5cac94b839ca07206f_feature%20page%20hero%20mov-transcode.webm" data-wf-ignore="true"/> */}
        </video>
        <div className="container-41 xs w-container">
          <div className="feature-content-copy">
            <div>
              <h1 data-w-id="c6a7633f-17f4-98ef-ace8-e7f92b76c426" style={{opacity:"1"}} className="herotext-white">
                White Label &amp; Online Apps Designed For <span className="herotext-yellow">Retail Businesses</span>
                </h1>
              </div>
                <p data-w-id="c6a7633f-17f4-98ef-ace8-e7f92b76c440" style={{opacity:"1"}} className="subtext white">Get your own branded mobile apps & online ordering for your retail business. Build your own brand value with in your customers.</p>
                <div className="div-block-155">
                  <a data-w-id="c6a7633f-17f4-98ef-ace8-e7f92b76c443" style={{opacity:"1"}} href="/contact" className="btn btn-primary py-2 px-4 ft-black w-inline-block btn-yellow">
                    <div className="text-block-2">Book A Demo</div>
                  </a>
                </div>
            </div>
          </div>
        </div>:
    <div className="pt-3 bg-dark hero-header ">
                <div className="container text-center mt-3 pt-5 pb-4">
                    <h1 className="display-6 text-white mb-3 animated slideInDown">{Breadcrumb?.value?.toUpperCase()}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">{Breadcrumb?.id}</li>
                        </ol>
                    </nav>
                </div>
            </div>}
            {/* {<TrustPilot/>} */}
</div>);
 
};
export default Banner;