import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SEO() {
  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>SkipTheQ - Always Stay Ahead</title>
        <meta name="description" content="SkipTheQ is a platform, that provides white labelled mobile & web apps to retail businesses." />
        <meta name="keywords" content="SkipTheQ, restaurants, cafes, bars, food-related businesses, Branded Website, Mobile Apps, Marketing Support, Customer Loyalty, Wessex House, Eastleigh"/>
        <meta property="og:title" content="SkipTheQ" />
        <meta property="og:description" content="SkipTheQ is a platform, that provides white labelled mobile & web apps to retail businesses. SkipTheQ innovative digital platform provides to business with a personalised brand name and an affordable way to go digital." />
        <meta property="og:image" content="https://www.skiptheq.co.uk/images/skiptheq.png" />
        <meta property="og:url" content="https://www.skiptheq.co.uk/about" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* rest of your component code here */}
    </div>
    </HelmetProvider>
  );
}

export default SEO;
